@media screen and (min-width: 0px) {
  .labelForm {
    &.MuiFormLabel-root {
      font-family: "Century Gothic Bold", sans-serif;
      font-size: 10px;
      line-height: 140%;
      letter-spacing: 0.2px;
      color: #333333;
    }
  }
}

@media screen and (min-width: 600px) {
  .labelForm {
    &.MuiFormLabel-root {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 1100px) {
  .labelForm {
    &.MuiFormLabel-root {
      font-size: 14px;
    }
  }

}

.PhoneInputInput {
  height: 45px;
  border-radius: 5px;
  border: 1px solid #9E9E9E ;
  font-family: "Century Gothic", sans-serif;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #9E9E9E;
  padding: 10px;

  &:focus-visible  {
    outline: none;
  }
}